import { L as LOG_EVENT_COLORS, a as LOG_EVENT_TEXTS } from "./log.event.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { staticClass: "detail-audit-log", attrs: { "after-close": _vm.afterModalClose, "destroy-on-close": "", "title": "Changes details" }, on: { "ok": _vm.closeModal }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.closeModal } }, [_vm._v("OK")])];
  }, proxy: true }]), model: { value: _vm.visible, callback: function($$v) {
    _vm.visible = $$v;
  }, expression: "visible" } }, [_c("div", { staticClass: "mb-3" }, [_c("div", { staticClass: "mb-1 h7 neutral-9--text" }, [_vm._v("Performed by")]), _c("div", { staticClass: "neutral-8--text" }, [_vm._v(_vm._s(_vm.log.createdBy))])]), _c("div", { staticClass: "mb-3" }, [_c("div", { staticClass: "mb-1 h7 neutral-9--text" }, [_vm._v("Event")]), _c("tag-field", { attrs: { "color": _vm.getLogEventColor, "parse": _vm.parseLogEvent, "value": _vm.log.mode } })], 1), _c("div", { staticClass: "mb-3" }, [_c("div", { staticClass: "mb-1 h7 neutral-9--text" }, [_vm._v("What changed")]), _c("div", { staticClass: "neutral-8--text" }, [_vm._v(_vm._s(_vm.log.docName) + " / " + _vm._s(_vm.log.docKey))])]), _c("div", [_c("div", { staticClass: "mb-1 h7 neutral-9--text" }, [_vm._v("Changes details")]), _vm._l(_vm.changeDetails, function(changeDetail, index2) {
    return _c("div", { key: index2, staticClass: "neutral-8--text" }, [_vm._v(" - " + _vm._s(changeDetail) + " ")]);
  })], 2)]);
};
var staticRenderFns$1 = [];
var DetailAuditLog_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "DetailAuditLog",
  inject: ["crud", "redirectRoute"],
  data() {
    return {
      visible: true
    };
  },
  computed: {
    changeDetails() {
      if (!this.log)
        return [];
      return this.log.changeDetails.split("<br/> ");
    },
    log() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    if (!this.log) {
      this.$router.push(this.redirectRoute);
    }
    this.visible = true;
  },
  methods: {
    closeModal() {
      this.visible = false;
    },
    getLogEventColor(value) {
      return LOG_EVENT_COLORS[value];
    },
    parseLogEvent(value) {
      return LOG_EVENT_TEXTS[value];
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var DetailAuditLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", { attrs: { "name": "sterilization.audit-logs", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/audit-logs", "resource-id-name": "transactionId" } }, [_c("detail-audit-log")], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    DetailAuditLog
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
